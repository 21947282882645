import { render, staticRenderFns } from "./CatalogsList.vue?vue&type=template&id=b828a864&scoped=true&"
import script from "./CatalogsList.vue?vue&type=script&lang=js&"
export * from "./CatalogsList.vue?vue&type=script&lang=js&"
import style0 from "./CatalogsList.vue?vue&type=style&index=0&id=b828a864&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b828a864",
  null
  
)

export default component.exports