<template>
  <ContentBlock overflow-hidden>
    <div class="subpage__title heading-3-sb grey-100">
      {{ $t('WiMultiuserTab.Ui.groupsBtn') }}

      <div class="subpage__title-actions">
        <SlButton
          :disabled="!$sl_hasAccess($sl_features.manageUsers)"
          @click="handleAddNewCatalog"
        >
          <template #prepend>
            <icon
              data="@icons/plus.svg"
              class="size-20 color-white-stroke"
            />
          </template>
          {{ $t('WiMultiuserTab.NewCatalog') }}
        </SlButton>
      </div>
    </div>
    <div class="subpage__table-wrapper">
      <SlTable
        id="roles-tab"
        :headers="tableHeaders"
        :rows="tableRows"
        unique-key="id"
        header-unique-key="key"
        header-name-key="name"
        header-type-key="type"
        :row-height="40"
        :initial-col-sizes="['40%', '56%', '40px']"
        :hidden-columns-keys="['id', 'ActionFgs']"
        :max-height="1000"
        :col-resize="false"
        :col-initial-width="40"
        :value-parser="parseValue"
        list
      >
        <template #header-ActionFgs-last />

        <template #ActionFgs-last="slotScope">
          <CatalogActionsDropdown
            v-if="!isBlockedCatalogs(slotScope)"
            :role="slotScope"
            :actions="slotScope.cellValue.val"
          />
        </template>
      </SlTable>
    </div>
  </ContentBlock>
</template>

<script>
import { mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import modalsIds from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';
import CatalogActionsDropdown from '@/components/User/ActionDropdowns/CatalogActionsDropdown.vue';

export default {
  name: 'CatalogsList',
  components: { CatalogActionsDropdown },
  mixins: [modal, access],
  props: {
    saveConfirm: Boolean
  },
  data() {
    return {
      modalsIds,
      blockedRowsIndexes: [0, 1],
      actionsConfig: [
        {
          flag: 0,
          label: this.$t('Web.CustomFields.EditAction'),
          icon: 'pen',
          action: (data) => this.handleEditCatalog(data)
        },
        {
          flag: 1,
          label: this.$t('Web.CustomFields.DeleteAction'),
          icon: 'trash',
          action: (data) => this.handleDeleteCatalog(data.row.id, data.row.Name.val)
        }
      ]
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.userAccess.permissions || {}
    }),
    tableRows() {
      return this.permissions.rows || [];
    },
    tableHeaders() {
      return this.permissions.headers || [];
    }
  },
  methods: {
    isBlockedCatalogs(scope) {
      return this.blockedRowsIndexes.includes(scope.rowIndex);
    },
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val || '';
    },
    handleAddNewCatalog() {
      this.$router.push({
        name: routeNames.CREATE_CATALOG
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/users/user-list.scss";
</style>
